<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12" class="py-0">
        <v-card class="px-7 py-7 rounded-lg" id="card-custom">
          <p class="headline-color">Data Pegawai</p>
          <v-divider />
          <v-row>
            <v-col cols="12" xl="4" lg="4" md="6" sm="6" xs="12">
              <v-btn-toggle v-model="type" class="mt-5" id="btn-type" mandatory>
                <v-btn small color="#1792E6" class="px-4 py-5">
                  <span class="text-capitalize font-weight-regular"
                    >Berdasarkan Unit Kerja</span
                  >
                </v-btn>
                <v-btn small color="#1792E6" class="px-4 py-5">
                  <span class="text-capitalize font-weight-regular"
                    >Berdasarkan Unit Kerja (Tree View)</span
                  >
                </v-btn>
                <v-btn small color="#1792E6" class="px-4 py-5">
                  <span class="text-capitalize font-weight-regular"
                    >Berdasarkan Nama</span
                  >
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" xl="8" lg="8" md="6" sm="6" xs="12">
              <v-card-actions class="pa-0">
                <v-spacer />
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="handleCreate"
                  >Tambah Pegawai<v-icon class="ml-2" color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row align="end">
            <template v-if="type == 0">
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units"
                  v-model="unit"
                  :loading="loadingUnit"
                  placeholder="Unit Utama"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_utama_id"
                  clearable
                  @change="changeUnit(1)"
                  @click:clear="changeUnit(1)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units2"
                  v-model="unit2"
                  :loading="loadingUnit"
                  placeholder="Unit Kerja"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_kerja_2_id"
                  clearable
                  @change="changeUnit(2)"
                  @click:clear="changeUnit(2)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units3"
                  v-model="unit3"
                  :loading="loadingUnit"
                  placeholder="Unit Kerja"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_kerja_3_id"
                  clearable
                  @change="changeUnit(3)"
                  @click:clear="changeUnit(3)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units4"
                  v-model="unit4"
                  :loading="loadingUnit"
                  placeholder="Unit Kerja"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_kerja_4_id"
                  clearable
                ></v-autocomplete>
              </v-col>
            </template>
            <template v-else-if="type == 1"> </template>
            <template v-else-if="type == 2">
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-text-field
                  placeholder="Masukan Nama Pegawai"
                  v-model="search"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </template>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-btn color="#FBB005" class="px-10" @click="getListEmployee">
                <span class="subtitle-2 text-capitalize">Cari</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="visibleColumn = true"
              small
              outlined
              class="px-4 font-weight-regular"
              color="#555555"
            >
              <v-icon class="mr-2">view_column</v-icon>
              Sesuaikan Kolom
            </v-btn>
            <v-btn
              @click="visibleFilter = true"
              small
              outlined
              class="px-4 font-weight-regular"
              color="#555555"
            >
              <v-icon class="mr-2">filter_list_alt</v-icon>
              Advanced Filter
            </v-btn>
          </v-card-actions>
          <v-row>
            <v-col>
              <template v-for="(value, propertyName, i) in advancedFilter">
                <v-chip
                  v-bind:key="i"
                  v-if="
                    (Array.isArray(value) && value.length > 0) ||
                      (value != null && !Array.isArray(value))
                  "
                  class="ma-2"
                  close
                  color="teal"
                  text-color="white"
                  @click:close="clearChip(propertyName)"
                >
                  <span>
                    {{ propertyName | snakeToTitle }}:
                    <template v-if="typeof value == 'object'">
                      <template v-if="Array.isArray(value)">
                        <span v-for="(item, i) in value" v-bind:key="i">
                          {{ item }}
                          <span v-if="i < value.length - 1"> ~ </span>
                        </span>
                      </template>
                    </template>
                    <template v-else>
                      <span>{{ value }}</span>
                    </template>
                  </span>
                </v-chip>
              </template>
            </v-col>
          </v-row>
          <v-card-actions v-if="employees && employees.length > 0">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="mx-2 px-5 text-capitalize"
              :loading="loadingExport"
              @click="exportDataPegawai"
            >
              <v-icon class="mr-2">file_download</v-icon>
              Informasi Pegawai
            </v-btn>
          </v-card-actions>
          <v-row>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              v-if="type == 1"
            >
              <v-treeview
                :value="selection"
                :active.sync="active"
                selection-type="independent"
                :open.sync="open"
                :items="unitsTree"
                item-text="singkatan"
                color="primary"
                @input="changeUnitTree"
                open-on-click
                return-object
                dense
                selectable
              >
              </v-treeview>
            </v-col>
            <v-col
              cols="12"
              :xl="type == 1 ? '9' : '12'"
              :lg="type == 1 ? '9' : '12'"
              :md="type == 1 ? '8' : '12'"
              :sm="type == 1 ? '6' : '12'"
              xs="12"
            >
              <v-data-table
                id="table-custom"
                class="mt-10"
                multi-sort
                :headers="headers"
                :items="employees"
                :loading="loading"
                :options.sync="options"
                :server-items-length="totalItem"
                :footer-props="{
                  'items-per-page-options': rowsPerPageItems
                }"
                loading-text="Loading... Please wait"
              >
                <template v-slot:[`item.NIP`]="{ item }">
                  <span
                    class="hover-primary"
                    @click="
                      getToPage('employee.detail', {
                        params: { employeeId: item.NIP }
                      })
                    "
                    >{{ item.NIP }}</span
                  >
                </template>
                <template v-slot:[`item.jabatan_id`]="{ item }">{{
                  item.jabatan
                }}</template>
                <template v-slot:[`item.unit_kerja_2_id`]="{ item }">{{
                  item.unit_kerja_2
                }}</template>
                <template v-slot:[`item.unit_kerja_3_id`]="{ item }">{{
                  item.unit_kerja_3
                }}</template>
                <template v-slot:[`item.unit_kerja_4_id`]="{ item }">{{
                  item.unit_kerja_4
                }}</template>
                <template v-slot:[`body.append`]="{ headers }">
                  <tr>
                    <td v-for="(header, index) in headers" :key="index">
                      <v-text-field
                        type="text"
                        :label="`Cari ${header.text}`"
                        v-model="header.filterText"
                        @change="getListEmployee"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </td>
                  </tr>
                </template>
                <template v-slot:no-data>
                  <ContentNotFound
                    message="Data pegawai dan presensi tidak ada"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visibleFilter" persistent max-width="800">
      <v-card class="pa-5 rounded-lg">
        <v-btn @click="visibleFilter = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          <v-icon class="mr-2">filter_list_alt</v-icon>
          Advanced Filter
        </v-card-text>
        <v-divider class="mb-2" />
        <p class="my-0">Status Aktif</p>
        <v-row>
          <v-col
            cols="3"
            class="py-0"
            v-for="(value, propertyName, index) in filter"
            :key="index"
          >
            <v-checkbox hide-details v-model="filter[propertyName]">
              <template #label>
                {{ propertyName | toTitle }}
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <div v-for="(filter, index) in dateFilter" :key="index">
          <p class="mt-6">{{ filter | snakeToTitle }}</p>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-menu
                v-model="advancedFilterMenu[`${filter}_start`]"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="advancedFilter[`${filter}_start`]"
                    placeholder="Tanggal Mulai"
                    append-icon="event"
                    outlined
                    dense
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="advancedFilter[`${filter}_start`]"
                  locale="id"
                  no-title
                  scrollable
                  @input="advancedFilterMenu[`${filter}_start`] = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-menu
                v-model="advancedFilterMenu[`${filter}_end`]"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="advancedFilter[`${filter}_end`]"
                    placeholder="Tanggal Akhir"
                    append-icon="event"
                    outlined
                    dense
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="advancedFilter[`${filter}_end`]"
                  locale="id"
                  no-title
                  scrollable
                  @input="advancedFilterMenu[`${filter}_end`] = false"
                />
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            @click="getListEmployee"
            >Cari</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="visibleColumn" persistent max-width="750">
      <v-card class="pa-5 rounded-lg">
        <v-btn @click="visibleColumn = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          <v-icon class="mr-2">view_column</v-icon>
          Sesuaikan Kolom
        </v-card-text>
        <v-divider />
        <v-row v-for="(groupName, index) in groupNames" :key="index">
          <v-col cols="12" class="pb-0">
            <p class="mb-0">{{ groupName | snakeToUppercase }}</p>
            <v-row>
              <v-col
                cols="3"
                class="pb-0"
                v-for="(item, index2) in headersTemp.filter(
                  data => data.group == groupName
                )"
                :key="index2"
              >
                <v-checkbox
                  v-model="item.active"
                  :value="item.value"
                  hide-details
                  dense
                >
                  <template #label>
                    <span class="subtitle-2 font-weight-regular">{{
                      item.text
                    }}</span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-divider class="my-5" />
          </v-col>
        </v-row>
        <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#717171"
            class="px-12 subtitle-2 text-capitalize"
            @click="visibleColumn = false"
            >Batal</v-btn
          >
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            @click="handleApply"
            >Buat</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PegawaiService from "@/services/resources/pegawai.service";
import DataUtamaService from "@/services/resources/data.utama.service";
const ContentNotFound = () => import("@/components/Content/NotFound");
import { mapFilterRootField } from "@/store/helpers";
import store from "@/store";

export default {
  components: {
    ContentNotFound
  },
  data() {
    return {
      selection: [],
      active: [],
      open: [],
      visible: false,
      visibleFilter: false,
      visibleColumn: false,
      menuStart: false,
      menuEnd: false,
      colFilter: null,
      dateFilter: [
        "tgl_lahir",
        "tgl_mulai_CPNS",
        "tgl_sk_CPNS",
        "tgl_mulai_PNS",
        "tgl_sk_PNS",
        "tgl_sk_gol",
        "tgl_sk_pangkat",
        "tgl_sk_eselon",
        "tgl_sk_jabatan",
        "tgl_status",
        "tgl_sk_status"
      ],
      advancedFilterMenu: {},
      advancedFilter: {
        status_aktif: ["Aktif"]
      },
      filter: {
        Aktif: true,
        Berhenti: false,
        Meninggal: false,
        Pensiun: false,
        Pindah: false
      },
      loadingUnit: false,
      loadingExport: false,
      unitsTree: [],
      units: [],
      units2: [],
      units3: [],
      units4: [],
      headersTemp: [],
      limitHeader: 15,
      headersActive: ["NIP", "nama", "jabatan_id", "status_aktif"],
      groupNames: [
        "data_diri",
        "alamat",
        "pendidikan",
        "informasi_lain",
        "informasi_pegawai",
        "cpns",
        "pns",
        "unit_kerja",
        "golongan",
        "pangkat",
        "eselon",
        "jabatan",
        "sk_status"
      ],
      headersData: [
        // Data Diri
        {
          text: "Nama",
          value: "nama",
          sortable: false,
          align: "center",
          group: "data_diri"
        },
        {
          text: "NIP",
          value: "NIP",
          sortable: false,
          align: "center",
          group: "data_diri"
        },
        {
          text: "Tempat Lahir",
          value: "tempat_lahir",
          sortable: false,
          align: "center",
          group: "data_diri"
        },
        {
          text: "Tanggal Lahir",
          value: "tgl_lahir",
          sortable: false,
          align: "center",
          group: "data_diri"
        },
        {
          text: "Kelamin",
          value: "kelamin",
          sortable: false,
          align: "center",
          group: "data_diri"
        },
        {
          text: "Agama",
          value: "agama",
          sortable: false,
          align: "center",
          group: "data_diri"
        },
        {
          text: "Status Diri",
          value: "status_diri",
          sortable: false,
          align: "center",
          group: "data_diri"
        },
        // Alamat
        {
          text: "Alamat",
          value: "alamat",
          sortable: false,
          align: "center",
          group: "alamat"
        },
        {
          text: "RT",
          value: "rt",
          sortable: false,
          align: "center",
          group: "alamat"
        },
        {
          text: "RW",
          value: "rw",
          sortable: false,
          align: "center",
          group: "alamat"
        },
        {
          text: "Kelurahan",
          value: "kel",
          sortable: false,
          align: "center",
          group: "alamat"
        },
        {
          text: "Kecamatan",
          value: "kec",
          sortable: false,
          align: "center",
          group: "alamat"
        },
        {
          text: "Kotamadya",
          value: "kotamadya",
          sortable: false,
          align: "center",
          group: "alamat"
        },
        {
          text: "Provinsi",
          value: "provinsi",
          sortable: false,
          align: "center",
          group: "alamat"
        },
        {
          text: "Kode Pos",
          value: "kode_pos",
          sortable: false,
          align: "center",
          group: "alamat"
        },
        // Pendidikan
        {
          text: "Kode Pendidikan",
          value: "kode_pend",
          sortable: false,
          align: "center",
          group: "pendidikan"
        },
        {
          text: "Kode Studi",
          value: "kode_studi",
          sortable: false,
          align: "center",
          group: "pendidikan"
        },
        {
          text: "Nama Sekolah",
          value: "nama_sekolah",
          sortable: false,
          align: "center",
          group: "pendidikan"
        },
        {
          text: "Tahun Lulus",
          value: "thn_lulus",
          sortable: false,
          align: "center",
          group: "pendidikan"
        },
        // Informasi Lain
        {
          text: "No KTP",
          value: "no_KTP",
          sortable: false,
          align: "center",
          group: "informasi_lain"
        },
        {
          text: "NPWP",
          value: "NPWP",
          sortable: false,
          align: "center",
          group: "informasi_lain"
        },
        {
          text: "No Taspen",
          value: "no_taspen",
          sortable: false,
          align: "center",
          group: "informasi_lain"
        },
        {
          text: "No HP",
          value: "hp_number",
          sortable: false,
          align: "center",
          group: "informasi_lain"
        },
        {
          text: "Email Address",
          value: "email_address",
          sortable: false,
          align: "center",
          group: "informasi_lain"
        },
        // Informasi Pegawai
        {
          text: "Status Aktif",
          value: "status_aktif",
          sortable: false,
          align: "center",
          group: "informasi_pegawai"
        },
        {
          text: "Status Pegawai",
          value: "status_peg",
          sortable: false,
          align: "center",
          group: "informasi_pegawai"
        },
        {
          text: "Jenis Pegawai",
          value: "jenis_peg",
          sortable: false,
          align: "center",
          group: "informasi_pegawai"
        },
        {
          text: "NIP Lama",
          value: "NIP_lama",
          sortable: false,
          align: "center",
          group: "informasi_pegawai"
        },
        // CPNS
        {
          text: "Tanggal Mulai CPNS",
          value: "tgl_mulai_CPNS",
          sortable: false,
          align: "center",
          group: "cpns"
        },
        {
          text: "No SK CPNS",
          value: "no_sk_CPNS",
          sortable: false,
          align: "center",
          group: "cpns"
        },
        {
          text: "Tanggal SK CPNS",
          value: "tgl_sk_CPNS",
          sortable: false,
          align: "center",
          group: "cpns"
        },
        // PNS
        {
          text: "Tanggal Mulai PNS",
          value: "tgl_mulai_PNS",
          sortable: false,
          align: "center",
          group: "pns"
        },
        {
          text: "No SK PNS",
          value: "no_sk_PNS",
          sortable: false,
          align: "center",
          group: "pns"
        },
        {
          text: "Tanggal SK PNS",
          value: "tgl_sk_PNS",
          sortable: false,
          align: "center",
          group: "pns"
        },
        {
          text: "PMK",
          value: "PMK",
          sortable: false,
          align: "center",
          group: "pns"
        },
        {
          text: "PMK Bulan",
          value: "PMK_bulan",
          sortable: false,
          align: "center",
          group: "pns"
        },
        {
          text: "No Karpeg",
          value: "no_karpeg",
          sortable: false,
          align: "center",
          group: "pns"
        },
        {
          text: "No Karis SU",
          value: "no_karis_su",
          sortable: false,
          align: "center",
          group: "pns"
        },
        // Unit Kerja
        {
          text: "Unit Utama",
          value: "unit_utama_id",
          sortable: false,
          align: "center",
          group: "unit_kerja"
        },
        {
          text: "UK Teknis",
          value: "uk_teknis_id",
          sortable: false,
          align: "center",
          group: "unit_kerja"
        },
        {
          text: "Unit Kerja 2",
          value: "unit_kerja_2_id",
          sortable: false,
          align: "center",
          group: "unit_kerja"
        },
        {
          text: "Unit Kerja 3",
          value: "unit_kerja_3_id",
          sortable: false,
          align: "center",
          group: "unit_kerja"
        },
        {
          text: "Unit Kerja 4",
          value: "unit_kerja_4_id",
          sortable: false,
          align: "center",
          group: "unit_kerja"
        },
        // Golongan
        {
          text: "Golongan",
          value: "gol",
          sortable: false,
          align: "center",
          group: "golongan"
        },
        {
          text: "TMT Golongan",
          value: "tmt_gol",
          sortable: false,
          align: "center",
          group: "golongan"
        },
        {
          text: "No SK Golongan",
          value: "no_sk_gol",
          sortable: false,
          align: "center",
          group: "golongan"
        },
        // Pangkat
        {
          text: "Pangkat",
          value: "pangkat_id",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "TMT Pangkat",
          value: "tmt_pangkat",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "No SK Pangkat",
          value: "no_sk_pangkat",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        {
          text: "Tanggal SK Pangkat",
          value: "tgl_sk_pangkat",
          sortable: false,
          align: "center",
          group: "pangkat"
        },
        // Eselon
        {
          text: "Eselon",
          value: "eselon_id",
          sortable: false,
          align: "center",
          group: "eselon"
        },
        {
          text: "TMT Eselon",
          value: "tmt_eselon",
          sortable: false,
          align: "center",
          group: "eselon"
        },
        {
          text: "No SK Eselon",
          value: "no_sk_eselon",
          sortable: false,
          align: "center",
          group: "eselon"
        },
        {
          text: "Tanggal SK Eselon",
          value: "tgl_sk_eselon",
          sortable: false,
          align: "center",
          group: "eselon"
        },
        // Jabatan
        {
          text: "Jabatan",
          value: "jabatan_id",
          sortable: false,
          align: "center",
          group: "jabatan"
        },
        {
          text: "TMT Jabatan",
          value: "tmt_jabatan",
          sortable: false,
          align: "center",
          group: "jabatan"
        },
        {
          text: "NO SK Jabatan",
          value: "no_sk_jabatan",
          sortable: false,
          align: "center",
          group: "jabatan"
        },
        {
          text: "Tanggal SK Jabatan",
          value: "tgl_sk_jabatan",
          sortable: false,
          align: "center",
          group: "jabatan"
        },
        // SK Status
        {
          text: "Tanggal Status",
          value: "tgl_status",
          sortable: false,
          align: "center",
          group: "sk_status"
        },
        {
          text: "No SK Status",
          value: "no_sk_status",
          sortable: false,
          align: "center",
          group: "sk_status"
        },
        {
          text: "Tanggal SK Status",
          value: "tgl_sk_status",
          sortable: false,
          align: "center",
          group: "sk_status"
        }
      ],
      headers: [],
      totalItem: 0,
      pageCount: 0,
      loading: true,
      options: {
        sortBy: ["NIP"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      employees: []
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getListEmployee);
      },
      deep: true
    },
    type(val) {
      if (val == 0) {
        this.search = null;
      } else if (val == 1) {
        this.unit = null;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      }
    },
    "advancedFilter.status_aktif"(val) {
      Object.entries(this.filter).forEach(([key]) => {
        const found = val.find(d => d == key) ? true : false;
        this.filter[key] = found;
      });
    },
    filter: {
      handler(val) {
        let status_aktif = [];
        Object.entries(val).forEach(([key, value]) => {
          if (value) {
            status_aktif.push(key);
          }
        });
        this.advancedFilter.status_aktif = status_aktif;
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListEmployee);
    }
  },
  computed: {
    paginationProperty() {
      return [this.headers].join();
    },
    ...mapFilterRootField({
      type: "pegawai.type",
      unit: "pegawai.unit",
      unit2: "pegawai.unit2",
      unit3: "pegawai.unit3",
      unit4: "pegawai.unit4",
      date: "pegawai.date",
      search: "pegawai.search"
    })
  },
  methods: {
    changeUnitTree(val) {
      val.map(d => {
        if (d.unit_utama_id) {
          this.unit = d.unit_utama_id;
          this.unit2 = null;
          this.unit3 = null;
          this.unit4 = null;
        }
        if (d.unit_kerja_2_id) {
          this.unit = null;
          this.unit2 = d.unit_kerja_2_id;
          this.unit3 = null;
          this.unit4 = null;
        }
        if (d.unit_kerja_3_id) {
          this.unit = null;
          this.unit2 = null;
          this.unit3 = d.unit_kerja_3_id;
          this.unit4 = null;
        }
        if (d.unit_kerja_4_id) {
          this.unit = null;
          this.unit2 = null;
          this.unit3 = null;
          this.unit4 = d.unit_kerja_4_id;
        }
      });
      this.selection = val.slice(-1);
    },
    clearChip(prop) {
      if (Array.isArray(this.advancedFilter[prop])) {
        this.advancedFilter[prop] = [];
      } else {
        this.advancedFilter[prop] = null;
      }
      this.getListEmployee();
    },
    handleCreate() {
      this.$store.commit("employee/resetEmployeeState");
      this.getToPage("employee.create");
    },
    handleApply() {
      const headerActive = this.headersTemp.filter(d => d.active != null);
      if (headerActive.length > this.limitHeader) {
        this.$store.commit("snackbar/setSnack", {
          show: true,
          message: `Hanya bisa memunculkan maksimal ${this.limitHeader} column`,
          color: "error"
        });
      } else {
        this.headers = this.headersTemp.filter(d => d.active != null);
        this.visibleColumn = false;
      }
    },
    changeUnit(type = 2) {
      if (type == 1) {
        this.units2 = this.units.find(
          d => d.unit_utama_id == this.unit
        )?.unit_kerja2;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 2) {
        this.units3 = this.units2.find(
          d => d.unit_kerja_2_id == this.unit2
        )?.unit_kerja3;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 3) {
        this.units4 = this.units3.find(
          d => d.unit_kerja_3_id == this.unit3
        )?.unit_kerja4;
        this.unit4 = null;
      }
    },
    populateUnit() {
      this.units2 = this.units.find(d =>
        d.unit_utama_id.includes(this.unit)
      )?.unit_kerja2;
      this.units3 = this.units2.find(
        d => d.unit_kerja_2_id == this.unit2
      )?.unit_kerja3;
      this.units4 = this.units3.find(
        d => d.unit_kerja_3_id == this.unit3
      )?.unit_kerja4;
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.unitsTree = data.map(d => {
                return {
                  ...d,
                  id: d.unit_utama_id,
                  children:
                    d.unit_kerja2.length == 0
                      ? undefined
                      : d.unit_kerja2.map(d2 => {
                          return {
                            ...d2,
                            id: d2.unit_kerja_2_id,
                            children:
                              d2.unit_kerja3.length == 0
                                ? undefined
                                : d2.unit_kerja3.map(d3 => {
                                    return {
                                      ...d3,
                                      id: d3.unit_kerja_3_id,
                                      children:
                                        d3.unit_kerja4.length == 0
                                          ? undefined
                                          : d3.unit_kerja4.map(d4 => {
                                              return {
                                                ...d4,
                                                id: d4.unit_kerja_4_id
                                              };
                                            })
                                    };
                                  })
                          };
                        })
                };
              });
              this.units = data;
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async exportDataPegawai() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      let filterObj = {};
      this.headers.forEach(d => {
        if (d.filterText) {
          filterObj[d.value] = d.filterText;
        }
      });
      Object.entries(this.advancedFilter).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          filterObj[key] = value;
        } else if (value) {
          filterObj[key] = value;
        }
      });
      try {
        this.loadingExport = true;
        await PegawaiService.getList({
          filter: {
            search: this.search,
            unit_utama_id: this.unit,
            unit_kerja_2_id: this.unit2,
            unit_kerja_3_id: this.unit3,
            unit_kerja_4_id: this.unit4,
            ...filterObj
          },
          isExport: true,
          column: this.headers.map(d => d.value),
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, file_url, message } = response.data;
            if (status) {
              const url = `${file_url}`;
              const fileName = file_url.split("/").slice(-1);
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `${fileName}`);
              document.body.appendChild(link);
              link.click();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingExport = false));
      } catch (err) {
        console.error(err);
      }
    },
    // Service
    async getListEmployee() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      let filterObj = {};
      this.headers.forEach(d => {
        if (d.filterText) {
          filterObj[d.value] = d.filterText;
        }
      });
      Object.entries(this.advancedFilter).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          filterObj[key] = value;
        } else if (value) {
          filterObj[key] = value;
        }
      });
      try {
        this.loading = true;
        await PegawaiService.getList({
          filter: {
            search: this.search,
            unit_utama_id: this.unit,
            unit_kerja_2_id: this.unit2,
            unit_kerja_3_id: this.unit3,
            unit_kerja_4_id: this.unit4,
            ...filterObj
          },
          column: this.headers.map(d => d.value),
          isExport: false,
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let employees = list;
              employees.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });
              this.visibleFilter = false;
              this.employees = employees;
              this.totalItem = parseInt(countTotal);
              this.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    if (this.type == 1) {
      this.unit = null;
      this.unit2 = null;
      this.unit3 = null;
      this.unit4 = null;
    }
    this.headers = this.headersData
      .filter(d => this.headersActive.find(d2 => d2 == d.value))
      .map(d => {
        d.filterText = "";
        return d;
      });
    this.headersTemp = this.headersData.map(d => {
      const selectedItem = this.headersActive.find(d2 => d2 == d.value);
      d.active = selectedItem ? d.value : null;
      return d;
    });
    this.advancedFilter = {
      ...this.advancedFilter,
      ...this.dateFilter.reduce((p, c) => {
        p[`${c}_start`] = null;
        p[`${c}_end`] = null;
        return p;
      }, {})
    };
    this.advancedFilterMenu = this.dateFilter.reduce((p, c) => {
      p[`${c}_start`] = false;
      p[`${c}_end`] = false;
      return p;
    }, {});
    this.getListUnit();
  },
  beforeRouteEnter(to, from, next) {
    const { NIP, role } = store.getters["auth/currentUser"];
    if (role === "USER") {
      next({
        name: "employee.detail",
        params: { employeeId: NIP }
      });
    } else {
      next();
    }
  }
};
</script>
<style lang="scss">
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
#card-custom .v-label {
  font-size: 0.775rem;
}
.v-treeview {
  font-size: 0.775rem;
}
</style>
